import { Center, Loader } from '@mantine/core';
import { type FC } from 'react';
import { css } from '@copilot-webapp-internal/style-system/css';

export const LoadingPage: FC = () => {
  return (
    <Center
      className={css({
        h: 'screen.height',
        w: 'screen.width',
      })}
    >
      <Loader size="md" type="bars" />
    </Center>
  );
};
