import { createTheme, MantineProvider } from '@mantine/core';
import type { FC, ReactNode } from 'react';

const theme = createTheme({
  primaryColor: 'violet',
  colors: {
    violet: [
      '#f4eaff',
      '#e1d2ff',
      '#bfa2fb',
      '#9b6ef6',
      '#7d42f2',
      '#6a27f0',
      '#6018f0',
      '#4f0bd6',
      '#4608c1',
      '#3a02a9',
    ],
  },
});

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <MantineProvider defaultColorScheme="light" theme={theme}>
      {children}
    </MantineProvider>
  );
};
