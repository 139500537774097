import { createAuth0Client } from '@auth0/auth0-spa-js';
import { environment } from '../../environment';

const auth0Client = createAuth0Client({
  clientId: environment.auth0.clientId,
  domain: environment.auth0.domain,
  useCookiesForTransactions: true,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/login-result`,
  },
});

export interface AuthClient {
  getUserEmail: () => Promise<string | null>;
  isAuthenticated: () => Promise<boolean>;
  getHeader: () => Promise<string>;
  getLoginRedirectUrl: () => Promise<string>;
  getSignupRedirectUrl: () => Promise<string>;
  handleLoginRedirect: () => Promise<string>;
  logout: () => Promise<void>;
}

const clientPromise = auth0Client.then((client) => {
  const getAuth0RedirectUrl = async (
    mode: 'login' | 'signup',
  ): Promise<string> => {
    const path = window.location.pathname;
    const search = window.location.search;
    const redirectTo = new URLSearchParams([
      ['redirectTo', path + search],
    ]).toString();
    const redirectUrl = await new Promise<string>((resolve) => {
      void client.loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}/login-result?${redirectTo}`,
          screen_hint: mode === 'login' ? undefined : 'signup',
        },
        openUrl: (url): void => {
          resolve(url);
        },
      });
    });
    return redirectUrl;
  };

  return {
    getUserEmail: async (): Promise<string | null> => {
      const user = await client.getUser();
      return user?.email ?? null;
    },
    isAuthenticated: async (): Promise<boolean> => {
      return client.isAuthenticated();
    },
    getHeader: async (): Promise<string> => {
      const token = await client.getTokenSilently();
      return `Bearer ${token}`;
    },
    getLoginRedirectUrl: async (): Promise<string> => {
      return getAuth0RedirectUrl('login');
    },
    getSignupRedirectUrl: async (): Promise<string> => {
      return getAuth0RedirectUrl('signup');
    },
    handleLoginRedirect: async (): Promise<string> => {
      const search = new URLSearchParams(window.location.search);
      if (search.has('code') && search.has('state')) {
        await client.handleRedirectCallback();
      }
      return search.get('redirectTo') ?? '/';
    },
    logout: async (): Promise<void> => {
      await client.logout();
    },
  };
});

export const getAuthClient = async (): Promise<AuthClient> => {
  return clientPromise;
};
