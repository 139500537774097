import type { Router } from '@remix-run/router';
import { createBrowserRouter, redirect } from 'react-router-dom';
import { getAuthClient } from '../auth/authClient';
import { ErrorPage } from '../pages/ErrorPage';
import { NotFoundPage } from '../pages/NotFoundPage';

export const router: Router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    ErrorBoundary: ErrorPage,
    children: [
      {
        id: 'login-routes',
        loader: async () => {
          const authClient = await getAuthClient();
          if (!(await authClient.isAuthenticated())) {
            const redirectUrl = await authClient.getLoginRedirectUrl();
            return redirect(redirectUrl);
          }
          return null;
        },
        children: [
          {
            id: 'logged-in-layout',
            lazy: () =>
              import('../layouts/LoggedInLayout/LoggedInLayout.loaded'),
            children: [
              {
                id: 'search-modal-routes',
                lazy: () =>
                  import('../layouts/SearchLayout/SearchLayout.loaded'),
                children: [
                  {
                    id: 'home',
                    index: true,
                    loader: async () => {
                      return redirect('/calls');
                    },
                  },
                  {
                    id: 'calls',
                    path: 'calls',
                    children: [
                      {
                        id: 'call-list',
                        index: true,
                        lazy: () =>
                          import('../pages/CallsPage/CallsPage.loaded'),
                      },
                      {
                        id: 'call-details',
                        path: ':callId',
                        lazy: () =>
                          import(
                            '../pages/CallDetailPage/CallDetailPage.loaded'
                          ),
                      },
                    ],
                  },
                  {
                    id: 'deal',
                    path: 'deals/:dealId',
                    lazy: () => import('../pages/DealPage/DealPage.loaded'),
                  },
                  {
                    id: 'settings',
                    path: 'settings',
                    children: [
                      {
                        id: 'settings-index',
                        index: true,
                        lazy: () =>
                          import('../pages/SettingsPage/SettingsPage.loaded'),
                      },
                    ],
                  },
                  {
                    id: 'integration',
                    path: 'integration',
                    lazy: () =>
                      import('../pages/IntegrationPage/IntegrationPage.loaded'),
                  },
                ],
              },
              {
                id: 'search-results',
                path: 'search-results',
                lazy: () =>
                  import('../pages/SearchResultPage/SearchResultPage.loaded'),
              },
            ],
          },
        ],
      },
      {
        id: 'signup-routes',
        loader: async () => {
          const client = await getAuthClient();
          if (!(await client.isAuthenticated())) {
            const redirectTo = await client.getSignupRedirectUrl();
            return redirect(redirectTo);
          }
          return null;
        },
        children: [
          {
            id: 'accept-invite',
            path: '/accept-invite',
            lazy: () =>
              import('../pages/AcceptInvitePage/AcceptInvitePage.loaded'),
          },
        ],
      },
      {
        id: 'login-result',
        path: '/login-result',
        loader: async () => {
          const client = await getAuthClient();
          const redirectTo = await client.handleLoginRedirect();
          return redirect(redirectTo);
        },
      },
      {
        id: 'not-found',
        path: '*',
        Component: NotFoundPage,
      },
    ],
  },
]);
