import {
  Box,
  Button,
  Container,
  Group,
  Paper,
  Text,
  Title,
} from '@mantine/core';
import type { FC } from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { css } from '@copilot-webapp-internal/style-system/css';
import { center } from '@copilot-webapp-internal/style-system/patterns';
import {
  ERROR_PAGE_BUTTON_TEXT,
  ERROR_PAGE_MESSAGE,
  ERROR_PAGE_TITLE,
} from './strings';

export const ErrorPage: FC = () => {
  const error = useRouteError();
  const errorMessage = error instanceof Error ? error.message : undefined;

  return (
    <Box
      className={css({
        bgColor: 'gray.0',
      })}
    >
      <Container
        className={center({
          minH: 'screen.height',
        })}
      >
        <Group
          className={css({
            flexDirection: 'column',
            alignItems: 'center',
          })}
        >
          <Title>{ERROR_PAGE_TITLE}</Title>
          <Text
            className={css({
              maxW: 'breakpoint-xs',
            })}
          >
            {ERROR_PAGE_MESSAGE}
          </Text>
          {!!errorMessage && (
            <Paper
              withBorder
              className={css({
                shadow: 'xs',
                bgColor: 'gray.0',
                w: 'full',
                maxW: 'breakpoint-xs',
                my: 'xl',
                p: 'lg',
              })}
            >
              <Container>
                <Text
                  className={css({
                    fontFamily: 'monospace',
                    fontSize: 'sm',
                  })}
                >
                  {errorMessage}
                </Text>
              </Container>
            </Paper>
          )}
          <Button component={Link} to="/">
            {ERROR_PAGE_BUTTON_TEXT}
          </Button>
        </Group>
      </Container>
    </Box>
  );
};
