import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app/App';
import { environment } from './environment';
import './index.css';

/* eslint-disable-next-line import/no-named-as-default-member, @typescript-eslint/no-non-null-assertion
 -- This is the idiomatic way to render a React app
 */
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Update the title of the page
document.title = environment.appTitle ?? 'Syntac';
