export interface Environment {
  appTitle: string | undefined;
  auth0: {
    domain: string;
    clientId: string;
  };
  graphqlServerURL: string;
}

const loadEnvironment = (): Environment => {
  // Check for environment variables. Note that in the final build,
  // these will be replaced by the actual values.
  // We can't use zod here because it would prevent dead code elimination and
  // increase the bundle size for the first load.

  if (!import.meta.env.VITE_AUTH0_CLIENT_ID) {
    throw new Error('Missing VITE_AUTH0_CLIENT_ID');
  }
  if (!import.meta.env.VITE_AUTH0_DOMAIN) {
    throw new Error('Missing VITE_AUTH0_DOMAIN');
  }
  if (!import.meta.env.VITE_GRAPHQL_SERVER_URL) {
    throw new Error('Missing VITE_GRAPHQL_SERVER_URL');
  }

  return {
    appTitle: import.meta.env.VITE_APP_TITLE,
    auth0: {
      domain: import.meta.env.VITE_AUTH0_DOMAIN,
      clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    },
    graphqlServerURL: import.meta.env.VITE_GRAPHQL_SERVER_URL,
  };
};

export const environment: Environment = loadEnvironment();
