import { Container, Title, Text, Button, Group, Box } from '@mantine/core';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@copilot-webapp-internal/style-system/css';
import { NotFoundIllustration } from './components/NotFoundIllustration';
import {
  NOT_FOUND_PAGE_BUTTON_TEXT,
  NOT_FOUND_PAGE_MESSAGE,
  NOT_FOUND_PAGE_TITLE,
} from './strings';

export const NotFoundPage: FC = () => {
  return (
    <Container
      className={css({
        py: '20',
      })}
    >
      <Box
        className={css({
          position: 'relative',
        })}
      >
        <NotFoundIllustration
          className={css({
            position: 'absolute',
            inset: '0',
            color: 'gray.0',
          })}
        />
        <Group
          className={css({
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            zIndex: 1,
            pt: '32',
            sm: {
              pt: '56',
            },
          })}
        >
          <Title
            className={css({
              textAlign: 'center',
            })}
            order={1}
          >
            {NOT_FOUND_PAGE_TITLE}
          </Title>
          <Text
            className={css({
              textAlign: 'center',
              maxW: 'breakpoint-xs',
              my: 'xl',
            })}
          >
            {NOT_FOUND_PAGE_MESSAGE}
          </Text>
          <Button component={Link} size="md" to="/">
            {NOT_FOUND_PAGE_BUTTON_TEXT}
          </Button>
        </Group>
      </Box>
    </Container>
  );
};
