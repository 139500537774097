import { mapObject } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const iconConfig = {
transform:({ size, ...rest }) => {
  if (size === "default") {
    return {
      ...rest,
      height: 5,
      width: 5,
      strokeWidth: 1.5
    };
  } else if (size === "historyItem") {
    return {
      ...rest,
      height: "70%",
      width: "70%"
    };
  }
  return {
    ...rest
  };
}}

export const getIconStyle = (styles = {}) => iconConfig.transform(styles, { map: mapObject })

export const icon = (styles) => css(getIconStyle(styles))
icon.raw = getIconStyle