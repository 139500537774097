import { Suspense, type FC, lazy } from 'react';
import { RouterProvider } from './routing/RouterProvider';
import { ThemeProvider } from './ThemeProvider';
import { LoadingPage } from './pages/LoadingPage';

const AuthClientProvider = lazy(() =>
  import('./auth/AuthClientProvider').then(({ AuthClientProvider: impl }) => ({
    default: impl,
  })),
);
const RelayEnvironmentProvider = lazy(() =>
  import('./relay-environment/RelayEnvironmentProvider').then(
    ({ RelayEnvironmentProvider: impl }) => ({ default: impl }),
  ),
);

export const App: FC = () => {
  return (
    <ThemeProvider>
      <Suspense fallback={<LoadingPage />}>
        <AuthClientProvider>
          <RelayEnvironmentProvider>
            <RouterProvider />
          </RelayEnvironmentProvider>
        </AuthClientProvider>
      </Suspense>
    </ThemeProvider>
  );
};
