import { RouterProvider as RouterProviderImpl } from 'react-router-dom';
import type { FC } from 'react';
import { LoadingPage } from '../pages/LoadingPage';
import { router } from './router';

export const RouterProvider: FC = () => {
  return (
    <RouterProviderImpl
      fallbackElement={<LoadingPage />}
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  );
};
